import React, { ReactElement } from "react";
import dynamic from "next/dynamic";
import styles from "./styles.module.scss";

const Footer = dynamic(() => import("@components/shared/footer"));
export default function MainLayout({ children }): ReactElement {
    return (
        <div className={styles["mainLayoutBg"]}>
            {children}
            <Footer />
        </div>
    );
}
